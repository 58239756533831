import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  DASHBOARD,
} from '../../utils/constants';


function IntroPage() {
  return (
    <>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={6}>
        <Grid item xs={3} md={3}>
          <Paper />
        </Grid>
        <Grid item xs={7} md={5}>
          <Box mr={4} mt={20}>
              <Typography variant="h1" gutterBottom>
                How To Get Started
              </Typography>
              <br />
              <Typography variant="h6">
                Go to Create Page and start real time translation
              </Typography>
              <br />
              <Typography variant="h6">
                Free plan only has 7 days trial
              </Typography>
              <br />
              <Typography variant="h6">
                We support history, you can view them Translation in  once you have created tasks
              </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} md={4}>
          <Paper />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
              <Grid item xs={3} md={4}>
                <Paper />
              </Grid>
              <Grid item xs={7} md={4}>
                <Box mr={4} mt={4}>
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={DASHBOARD}
                  >
                    Get Started
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} md={4}>
                <Paper />
              </Grid>
            </Grid>
    </Paper>
    </>
  );
}


export default IntroPage;