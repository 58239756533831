import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none'
  },
  media: {
    height: 110,
    width: 400
  },
}));
