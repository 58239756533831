import React from "react";
import {
  Button,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "@material-ui/core";


const KEY_ARRAY= ['UpdatedAt', 'Source Language', 'Target Language', 'Download'];

export default function TableComponent({ data }) {


  if(!Array.isArray(data) || !data.length || !data[0]) {
    return (null);
  }

  const sortedData = data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <TableContainer>
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {KEY_ARRAY.map(key => (
            <TableCell style={{
            }} key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map(({ id, updatedAt, sourceLanguage, targetLanguage, s3DownloadAddress }) => (
          <TableRow key={id}>
            <TableCell>{updatedAt}</TableCell>
            <TableCell>{sourceLanguage}</TableCell>
            <TableCell>{targetLanguage}</TableCell>
            <TableCell>
              {
                s3DownloadAddress ?
                <Button
                  target="_blank"
                  href={s3DownloadAddress}
                  color="primary"
                  variant="contained"
                >
                  Download
                </Button> :
                <Button
                  disabled
                  variant="contained"
                >
                  Not Available
                </Button>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
}
