import React, { Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Layout from "./Layout";
import ProtectedRoute from '../components/ProtectedRoute';

// pages
import IntroPage from '../pages/IntroPage';
import Dashboard from "../pages/dashboard";
import TasksPage from '../pages/TasksPage';
import Error from "../pages/error";

import ProfilePage from '../pages/ProfilePage';
import SupportPage from "../pages/SupportPage";
import UpgradePage from "../pages/UpgradePage";
import RecentPage from '../pages/RecentPage';
import StarredPage from '../pages/StarredPage';
import UsagePage from '../pages/UsagePage';

// Routes
import {
  INTRO,
  DASHBOARD,
  USAGE,
  TASKS,
  RECENT,
  STARRED,
  UPGRADE,
  SUPPORT,
  PROFILE,
  ERROR_500,
  CATCH_ALL
} from '../utils/constants';

const HeaderSideBarElement = (WrappedComponent) => (props) => {
  return (
    <Layout>
      <WrappedComponent {...props}/>
    </Layout>
  )
}

export default function App() {
  return (
    <AnimatePresence>
      <Suspense>
        <Switch>
          <ProtectedRoute exact path={INTRO} component={HeaderSideBarElement(IntroPage)} />
          <ProtectedRoute exact path={DASHBOARD} component={HeaderSideBarElement(Dashboard)} />
          <ProtectedRoute exact path={TASKS} component={HeaderSideBarElement(TasksPage)} />

          <ProtectedRoute exact path={PROFILE} component={HeaderSideBarElement(ProfilePage)} />
          <ProtectedRoute exact path={SUPPORT} component={HeaderSideBarElement(SupportPage)} />
          <ProtectedRoute exact path={UPGRADE} component={HeaderSideBarElement(UpgradePage)} />
          <ProtectedRoute exact path={RECENT} component={HeaderSideBarElement(RecentPage)} />
          <ProtectedRoute exact path={STARRED} component={HeaderSideBarElement(StarredPage)} />
          <ProtectedRoute exact path={USAGE} component={HeaderSideBarElement(UsagePage)} />

          <Route path={ERROR_500} component={Error} />
          <Route path={CATCH_ALL} component={Error} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
}
