import AuthOptions from './AuthOptions';
import ThemeOptions from './ThemeOptions';
import DashOptions from './DashOptions';

const options = {
  AuthOptions,
  ThemeOptions,
  DashOptions
};

export default  options;
