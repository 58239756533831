import React, { useState, useEffect } from "react";
import { Circle } from 'rc-progress';
import { connect } from 'react-redux';
import {
  Button,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Container
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
// components
import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
// Route
import {
  UPGRADE,
} from '../../utils/constants';

const BASIC_PLAN_DAY_LIMIT = 7; //same as lambda constant

function UsagePage(props) {
  const {
    accessTokenState,
    planexpireddate,
    plan
  } = props;
  var theme = useTheme();
  const [freeplanPercentage, setFreeplanPercentage] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(true);


  useEffect(() => {
    accessTokenState && planexpireddate && (() => {
      const currentDate = new Date();
      const userexpiredDate = new Date(planexpireddate);
      let datePer = 1;
      if(currentDate > userexpiredDate) {
        //console.error('User plan expired, return');
        datePer = 100;
      } else {
        datePer = (1-((userexpiredDate - currentDate)/(BASIC_PLAN_DAY_LIMIT * 24 * 60 * 60 * 1000))).toFixed(2)*100;
      }
      if(plan === 'pro') {
        datePer = 1;
      }
      setFreeplanPercentage(datePer);
      setIsPageLoading(false);
    })();
  },[accessTokenState, planexpireddate, plan]);

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={4}>
          <Grid container spacing={4}>
            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={10} md={6}>
                <Box m={4}>
                  <Widget title="Instruction" disableWidgetMenu>
                    <div >
                      <Typography variant="h6">
                        Current Plan Usage {freeplanPercentage}%
                      </Typography>
                    </div>
                  </Widget>
                </Box>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={10} md={6}>
                <Container maxWidth="sm">
                  <Circle percent={freeplanPercentage} strokeWidth="4" strokeColor={theme.palette.success.main} />
                </Container>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item xs={3} md={5}>
                <Paper />
              </Grid>
              <Grid item xs={6} md={4}>
                <Box mt={4}>
                  <Button
                    disabled={plan === 'pro'}
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={UPGRADE}
                  >
                    Request Upgrade Plan
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} md={2}>
                <Paper />
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    </Paper>
    </>
  );
}

const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
  planexpireddate: state.AuthOptions.planexpireddate,
  plan: state.AuthOptions.plan
});

export default connect(mapStateToProps)(UsagePage);