import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import ButtonBase from '@material-ui/core/ButtonBase';

// styles
import useStyles from "./styles";


function MyCard(props) {
  var classes = useStyles();
  const { isRecording, isStartButton, onButtonClick } = props;
  let imageSrc =  '/start.png';
  if (isStartButton) {
    imageSrc = isRecording ? '/start2.png' : '/start.png';
  } else {
    imageSrc = isRecording ? '/stop.png' : '/stop2.png';
  }


  return (
    <Card className={classes.root}>
      <ButtonBase
          onClick={() => onButtonClick(onButtonClick)}
      >
        <CardMedia
          image={imageSrc}
          className={classes.media}
        />
      </ButtonBase>
    </Card>
  );
}

export default MyCard;