import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import ButtonBase from '@material-ui/core/ButtonBase';

// styles
import useStyles from "./styles";


function MyCard(props) {
  var classes = useStyles();
  const { languageCode, onButtonClick, languageState } = props;
  const imageSrc = languageState === languageCode ? `/${languageCode}2.png` : `/${languageCode}.png`;

  return (
    <Card className={classes.root}>
      <ButtonBase
          onClick={() => onButtonClick(languageCode)}
      >
        <CardMedia
          image={imageSrc}
          className={classes.media}
        />
      </ButtonBase>
    </Card>
  );
}

export default MyCard;