import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Box,
  Grid,
  LinearProgress,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
// components
import Table from "../../components/Table/Table";
import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
// API
import {
  formatGetAllPostsURL,
  formatUpdatePostURL
 } from '../../utils/apis';
// Route
import {
  DASHBOARD,
} from '../../utils/constants';
// Formatter
import {
  formatRequestHeader
} from '../../utils/formatter';

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

function TasksPage(props) {
  const { accessTokenState } = props;
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const onStarClick = async (id, isToStar) => {
    startTask(id, isToStar);

    await axios.patch(formatUpdatePostURL(id),
      {
        'isStarred': isToStar,
      },
      {
        headers: formatRequestHeader(accessTokenState)
      }
      ).then(resp => {
        //console.log(resp);
    }).catch(error => {
        console.error(error);
        startTask(id, !isToStar);
    });
  }

  const startTask = (id, isToStar) => {
    const deepcopy = JSON.parse(JSON.stringify(tasks)); ;
    deepcopy.forEach(item => {
      if(item['id'] === id) {
        item['isStarred'] = isToStar;
      }
    })
    setTasks(deepcopy);
  }

  useEffect(() => {
    accessTokenState && axios.get(formatGetAllPostsURL(), {
      headers: formatRequestHeader(accessTokenState)
    }).then(resp => {
      // Use for tracking the mounted status
      setTasks(resp['data']['items']);
      setIsPageLoading(false);
    }).catch(error => {
        console.error(error);
        setIsPageLoading(false);
    });
  },[accessTokenState]);

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={4}>
        {
          tasks.length !== 0 ?
          <Grid item xs={12}>
            <Table
            onStarClick={onStarClick}
            data={tasks} />
          </Grid>
          :
          <Grid container spacing={4}>
            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={10} md={6}>
                <Box m={4}>
                  <Widget title="Instruction" disableWidgetMenu>
                    <div className={classes.dashedBorder}>

                      <Typography variant="h6">
                        You do not have any tasks
                      </Typography>
                      <Typography variant="h6">
                        Go to Dashboard page to get started
                      </Typography>

                    </div>
                  </Widget>
                </Box>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={3} md={5}>
                <Paper />
              </Grid>
              <Grid item xs={7} md={4}>
                <Box mr={4} mt={4}>
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={DASHBOARD}
                  >
                    Get Started
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} md={3}>
                <Paper />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </Paper>

    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(TasksPage);