export const SET_SOURCE_LANGUAGE = 'DASH_OPTIONS/SET_SOURCE_LANGUAGE';
export const SET_TARGET_LANGUAGE = 'DASH_OPTIONS/SET_TARGET_LANGUAGE';

export const setSourceLanguage = sourceLanguage => ({
  type: SET_SOURCE_LANGUAGE,
  sourceLanguage: sourceLanguage
});

export const setTargetLanguage = targetLanguage => ({
  type: SET_TARGET_LANGUAGE,
  targetLanguage: targetLanguage
});

export default function reducer(
  state = {
    // default source language
    sourceLanguage: 'en',
    // default target language
    targetLanguage: 'en',
  },
  action,
) {
  switch (action.type) {
    case SET_SOURCE_LANGUAGE:
      return {
        ...state,
        sourceLanguage: action.sourceLanguage
      };
    case SET_TARGET_LANGUAGE:
      return {
        ...state,
        targetLanguage: action.targetLanguage
      };
    default:
      break;
  }
  return state;
}
