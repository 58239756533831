import React, { useState, useEffect } from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Grid, Paper} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// components
import Card from '../../components/Card/Card';
import RecordButton from '../../components/RecordButton/RecordButton';
import { Typography } from "../../components/Wrappers";


// Redux
import { setSourceLanguage, setTargetLanguage } from '../../reducerCollection/DashOptions';


import {
  formatCreatePostURL
} from '../../utils/apis';

import { formatRequestHeader } from '../../utils/formatter';


import useStyles from "./styles";

function Dashboard(props) {
  const {
    accessTokenState,
    sourceLanguageState,
    targetLanguageState } = props;
  const classes = useStyles();
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recorder, setRecorder] = useState();
  const [getUserMediaStream, setGetUserMediaStream] = useState();
  const [audioSrc, setAudioSrc] = useState();
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleErrorOpen = () => {
    setErrorOpen(true);
  }

  const handleErrorClose = () => {
    setErrorOpen(false);
  }

  const AWS = window.AWS;
  const awsRegion = 'us-east-1';
  const bucketName = 'voicetranslatorapp-voicetranslatorbucket-183tl25kya2k7';
  const IdentityPoolId = 'us-east-1:ca4a91cb-070b-4f83-887f-38fa42edc2c3';
  const lambdaFunction = 'VoiceTranslatorApp-VoiceTranslatorLambda-DTGQ6X19PQPM';
  // S3 object for storing input and output audio
  var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: bucketName},
    region: awsRegion
  });

  useEffect(() => {
     AWS.config.update({
      region: awsRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });

  });

  const sourceLangOnClick = (languageCode) => {
    props.setSourceLanguage(languageCode);
  };

  const sourceLanguageList = [
    {
      code: 'en',
      wording: 'US English'
    },
    {
      code: 'es',
      wording: 'US Spanish'
    }
  ];

  const targetLangOnClick = (languageCode) => {
    props.setTargetLanguage(languageCode);
  };

  const targetLanguageList = [
    {
      code: 'en',
      wording: 'US English'
    },
    {
      code: 'gb',
      wording: 'British English'
    },
    {
      code: 'de',
      wording: 'German'
    },
    {
      code: 'pl',
      wording: 'Polish'
    },
    {
      code: 'es',
      wording: 'US Spanish'
    },
    {
      code: 'ca',
      wording: 'Canadian French'
    },
    {
      code: 'fr',
      wording: 'French'
    },
    {
      code: 'ja',
      wording: 'Japanese'
    },
    {
      code: 'ru',
      wording: 'Russian'
    },
    {
      code: 'it',
      wording: 'Italian'
    },
    {
      code: 'sv',
      wording: 'Swedish'
    }
  ];

  const onStartButtonClick = () => {
    startRecording();
  }

  const onStopButtonClick = () => {
    stopRecording();
  }


  // Generate unique identifiers
  function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
  // Generate request ID
  function generateRequestId() {
    return guid();
  }

  // Record audio with device microphone
  function startRecording() {
    setIsRecording(true);
    var AudioContext = window.AudioContext || window.webkitAudioContext;
    let audioContext = new AudioContext();

    // Define constraints object for MediaStream
    var constraints = { audio: true, video: false }

    // Access MediaDevices to get audio stream
    navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
      setGetUserMediaStream(stream);
      let recorderInput = audioContext.createMediaStreamSource(stream);
      // Create Recorder.js object and start recording
      // setRecorder(new window.Recorder(recorderInput, { numChannels: 1 }))
      // recorder.record()
      let aRecorder = new window.Recorder(recorderInput, { numChannels: 1 })
      setRecorder(aRecorder);
      aRecorder.record();
    }).catch(function(err) {
      alert(err);
      //Reset buttons and message in case of failure
      setIsRecording(false);

      // Inform user that recording failed (most likely was blocked by browser due
      // to insecure origin)
      alert("....Recording failed, try using Firefox or local copy of the app from your machine.");
    });
  }

  function stopRecording() {
    //Reset buttons and message
    setIsRecording(false);

    // Stop recording with Recorder.js object
    recorder.stop();

    // Stop microphone and get recorded audio
    getUserMediaStream.getAudioTracks()[0].stop();

    // Pass blob with audio data to callback
    recorder.exportWAV(uploadAudioRecording)

  }

  function uploadAudioRecording(blob) {
    setIsProcessing(true);
    // Generate unique ID for upload audio file request
    var requestId = generateRequestId();

    // Create key for S3 object and upload input audio file
    var inputKey = 'input/' + requestId + '.wav'

    s3.upload({
      Key: inputKey,
      Body: blob
    }, function(err, data) {
      if (err) {
        setIsProcessing(false);
        return alert('There was an error uploading your recording: ', err.message);
      } else {

        var lambda = new AWS.Lambda({region: awsRegion, apiVersion: '2015-03-31'});
        var input = {
          FunctionName : lambdaFunction,
          InvocationType : 'RequestResponse',
          LogType : 'None',
          Payload: JSON.stringify({"bucket": bucketName, "key": inputKey, "sourceLanguage": sourceLanguageState, "targetLanguage" : targetLanguageState})
        };

        lambda.invoke(input, async function(err, data) {
              if (err) {
                setIsProcessing(false);
                console.log(err);
                alert("There was a problem with Lambda function!!! ");
              } else {
                var resultUrl = data.Payload.replace(/['"]+/g, '');
                const s3DownloadAddress = `https://${bucketName}.s3.amazonaws.com/${resultUrl}`;
                //Store address to DB
                await axios.post(formatCreatePostURL(),
                  {
                    'bucketName': bucketName,
                    'inputKey': inputKey,
                    'outputKey': resultUrl,
                    'sourceLanguage': sourceLanguageState,
                    'targetLanguage': targetLanguageState,
                    's3DownloadAddress': s3DownloadAddress
                  },
                  {
                    headers: formatRequestHeader(accessTokenState)
                  }
                  ).then((resp) => {

                    // console.log('after update DB');
                    // console.log(resp);
                    setIsProcessing(false);
                    if(resp['data']['statusCode'] === 400) {
                      handleErrorOpen();
                    } else {
                      setAudioSrc(s3DownloadAddress);
                    }
                  }).catch(error => {
                      if(error.response.status === 400) {
                        handleErrorOpen();
                      } else {
                        console.error(error);
                      }
                      setIsProcessing(false);

                  });
              }
            });
    }
  });
}


  return (
    <>
    <Dialog
      open={errorOpen}
      onClose={handleErrorClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You exceed your free quota, please contact us for an upgrade.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleErrorClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={12} md={6}>
              <Typography variant="h1">
                Select Source Language
              </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper></Paper>
          </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Paper/>
        </Grid>
        {
          sourceLanguageList.map((value, index) => {
            return (<Grid key={index} item lg={2} md={2} sm={2} xs={12}>
              <Card
                languageCode={value['code']}
                onButtonClick={sourceLangOnClick}
                languageState={sourceLanguageState}
              />
              <Box m={2}>
                <Typography className={classes.text} size="md">
                  {value['wording']}
                </Typography>
              </Box>
            </Grid>
            )
          })
        }
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Paper/>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={12} md={6}>
              <Typography variant="h1">
                Select Target Language
              </Typography>
          </Grid>
         <Grid item xs={12} md={3}>
            <Paper></Paper>
          </Grid>
      </Grid>

      <Grid container spacing={4}>
        {
          targetLanguageList.map((value, index) => {
            return (<Grid key={index} item lg={2} md={2} sm={2} xs={12}>
              <Card
                languageCode={value['code']}
                onButtonClick={targetLangOnClick}
                languageState={targetLanguageState}
              />
              <Box m={2}>
                <Typography className={classes.text} size="md">
                  {value['wording']}
                </Typography>
              </Box>
            </Grid>
            )
          })
        }
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={2}>
          <Paper></Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <RecordButton
            isRecording={isRecording}
            isStartButton={true}
            onButtonClick={onStartButtonClick}/>
            {
              isRecording &&
              <Box m={2}>
                <Typography className={classes.text} size="md">
                  Recording...
                </Typography>
              </Box>
            }
        </Grid>
        <Grid item xs={12} md={5}>
          <RecordButton
            isRecording={isRecording}
            isStartButton={false}
            onButtonClick={onStopButtonClick}/>
            {
              isProcessing &&
              <Box m={2}>
                <Typography className={classes.text} size="md">
                  Processing...
                </Typography>
              </Box>
            }
        </Grid>
        <Grid item xs={12} md={2}>
          <Paper></Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper></Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          {
            audioSrc &&
            <Box m={4}>
              <AudioPlayer
              autoPlay
              src={audioSrc}
            />
            </Box>
          }
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper></Paper>
        </Grid>
      </Grid>

    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
  sourceLanguageState: state.DashOptions.sourceLanguage,
  targetLanguageState: state.DashOptions.targetLanguage
});

const mapDispatchToProps = dispatch => ({
  setSourceLanguage: (languageCode)  => dispatch(setSourceLanguage(languageCode)),
  setTargetLanguage: (languageCode)  => dispatch(setTargetLanguage(languageCode))
});


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);