const API_DOMAIN = 'https://ydf6apsjae.execute-api.us-east-1.amazonaws.com/prod';

export const formatGetAllPostsURL = () => {
  return `${API_DOMAIN}/post`;
}

export const formatCreatePostURL = () => {
  return `${API_DOMAIN}/post`;
}

export const formatUpdatePostURL = (postId) => {
  return `${API_DOMAIN}/post/${postId}`;
}

export const formatGetUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatCreateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateSupportURL = () => {
  return `${API_DOMAIN}/support`;
}